import { useMemo } from 'react'
import { isAfter } from 'date-fns'
import { theatricalGoalMilestones } from '@/constants/theatricalGoalMilestones'
import { getWebClient } from '@/services/ApolloClient'
import { useGoal } from '@/services/GoalService'
import { useTranslate } from '@/utils/translate/translate-client'
import { getDayBefore } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'

export interface GoalLineInfo {
  ticketsSold: number
  goalLineTitle: string
  milestonePercentage: number
  currentMilestone: number
  isLastMilestone: boolean
}

function isLastOfArray(element: number, array: number[]) {
  if (Array.isArray(array) && array.length > 0) {
    return element === array[array.length - 1]
  }
  return false
}

interface Props {
  releaseDate: string
  theatricalSlug: string
  isPossumTrotExperiment?: boolean
}
const useTheatricalGoalService = ({ releaseDate, theatricalSlug, isPossumTrotExperiment }: Props) => {
  const { t } = useTranslate('common')
  const client = getWebClient()
  const milestones = useMemo(() => {
    if (isPossumTrotExperiment) return [1000000]
    return theatricalGoalMilestones[theatricalSlug] ?? theatricalGoalMilestones.default
  }, [isPossumTrotExperiment, theatricalSlug])

  const { raisedRaw } = useGoal({
    slug: theatricalSlug ?? '',
    client,
  })

  const goalLineTitle = useMemo(() => {
    if (!releaseDate) return t('totalPreSales', 'Total Pre Sales')
    const isInBoxOffice = isAfter(new Date(), getDayBefore(new Date(releaseDate)))
    if (isInBoxOffice) return t('totalBoxOffice', 'Total Box Office')
    return t('totalPreSales', 'Total Pre Sales')
  }, [t, releaseDate])

  const goalLineInfo: GoalLineInfo = useMemo(() => {
    const ticketsSold = raisedRaw ? Math.round(raisedRaw / 100 / 14) : 0
    const currentUsGoalMilestone = milestones?.find((m: number, index: number) => {
      if (m > ticketsSold) return m
      if (milestones.length - 1 === index) return m
    })
    const currentMilestone = currentUsGoalMilestone ?? 0
    const hasMilestonePercentage = ticketsSold === 0 && currentMilestone === 0
    const milestonePercentage = hasMilestonePercentage ? 0 : Math.floor((ticketsSold / currentMilestone) * 100)

    return {
      ticketsSold,
      goalLineTitle,
      milestonePercentage,
      isLastMilestone: isLastOfArray(currentMilestone, milestones),
      currentMilestone,
    }
  }, [goalLineTitle, milestones, raisedRaw])

  return { goalLineInfo }
}

export default useTheatricalGoalService
