import { useEffect, useState } from 'react'

export const useIncrementer = (initialValue: number, initialValueMultiplier = 0.75) => {
  const [value, setValue] = useState(initialValue * initialValueMultiplier)

  useEffect(() => {
    setValue(initialValue * initialValueMultiplier)
    // increment displayAmount until it reaches the resolvedAmount
    const interval = setInterval(() => {
      setValue((prevDisplayAmount) => {
        const updatedAmount = prevDisplayAmount + prevDisplayAmount * 0.01
        if (updatedAmount < initialValue) {
          return updatedAmount
        }

        // stop incrementing when displayAmount reaches resolvedAmount
        clearInterval(interval)
        return initialValue
      })
    }, 50)

    return () => {
      clearInterval(interval)
    }
  }, [initialValue, initialValueMultiplier])

  return value
}
