import React, { FC, ReactNode, useState } from 'react'
import { autoPlacement } from '@floating-ui/core'
import { arrow, shift } from '@floating-ui/dom'
import { autoUpdate, useFloating } from '@floating-ui/react-dom'
import { Popover as HeadlessPopover } from '@headlessui/react'
import classNames from 'classnames'
import { FlexRow } from '@/atoms/FlexContainers'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { Text } from '@/atoms/Text'

interface Props {
  title: string
  description: string
  icon?: ReactNode
  triggerComponent: ReactNode
  buttonClassName?: string
  isDarkMode?: boolean
  className?: string
}

export const Popover: FC<Props> = ({
  title,
  description,
  icon,
  buttonClassName,
  triggerComponent,
  isDarkMode = true,
  className,
}) => {
  const [arrowEl, setArrowEl] = useState<Element>()
  const { refs, floatingStyles, middlewareData } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
      autoPlacement({ allowedPlacements: ['bottom', 'bottom-start', 'bottom-end'] }),
      shift(),
      arrow({ element: arrowEl as Element, padding: 18 }),
    ],
  })

  const descriptionParagraphs = description.split('\n')

  return (
    <HeadlessPopover className={classNames('relative z-40', className)}>
      <HeadlessPopover.Button ref={refs.setReference} className={classNames('cursor-pointer', buttonClassName)}>
        {triggerComponent}
      </HeadlessPopover.Button>
      <HeadlessPopover.Panel
        ref={refs.setFloating}
        style={floatingStyles}
        className={classNames(
          'w-[350px] !top-1.5 p-4 md:p-6 !z-50 text-left rounded-2xl',
          isDarkMode
            ? 'bg-core-gray-800 drop-shadow-light-2 md:drop-shadow-light-3'
            : 'bg-white drop-shadow-dark-2 md:drop-shadow-dark-3',
        )}
      >
        <div
          ref={(node) => setArrowEl(node as Element)}
          style={{ left: middlewareData.arrow?.x, top: middlewareData.arrow?.y }}
          className={classNames(
            'absolute -top-1 h-3.5 w-3.5 md:h-4 md:w-4 rounded-[2px] md:rounded-[3px] rotate-45',
            isDarkMode ? 'bg-core-gray-800' : 'bg-white',
          )}
        />
        <FlexRow className="relative z-50 gap-1">
          <span className="mb-auto"> {icon}</span>
          <Text
            className="photon-title-xxs md:photon-title-xs font-bold"
            color={isDarkMode ? 'white' : 'core-gray-950'}
            weight="bold"
          >
            {title}
          </Text>
          <HeadlessPopover.Button className="ml-auto">
            <CloseIcon className="md:hidden" size={18} color={isDarkMode ? 'core-gray-500' : 'core-gray-700'} />
            <CloseIcon className="hidden md:block" size={20} color={isDarkMode ? 'core-gray-500' : 'core-gray-700'} />
          </HeadlessPopover.Button>
        </FlexRow>
        {descriptionParagraphs.map((paragraph) => {
          return (
            <Text
              key={paragraph}
              color={isDarkMode ? 'white' : 'core-gray-950'}
              className="photon-caption-sm md:photon-caption-md mt-2 block font-normal"
            >
              {paragraph}
            </Text>
          )
        })}
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  )
}
