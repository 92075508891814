import { slugs } from '@/constants'

const defaultMilestones = [
  5000, 10000, 25000, 50000, 75000, 100000, 150000, 250000, 500000, 750000, 1000000, 1500000, 2000000,
]
export const theatricalGoalMilestones = {
  [slugs.cabrini]: defaultMilestones,
  [slugs.sight]: defaultMilestones,
  [slugs.soundOfFreedom]: defaultMilestones,
  [slugs.soundOfHopeTheStoryOfPossumTrot]: [1000000],
  [slugs.theShift]: [5000, 10000, 25000, 50000, 75000, 100000, 150000, 250000, 500000, 750000, 1000000],
  default: defaultMilestones,
}
