import React from 'react'
import Script from 'next/script'
import { reportErrorToBugsnag } from '@/utils/bugsnag'

export const isCaptchaReady = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      grecaptcha.enterprise.ready(() => {
        resolve()
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      reportErrorToBugsnag(err?.message ?? 'grecaptcha failed to load')
      reject()
    }
  })
}
export const generateRecaptchaToken = async (action: string) => {
  try {
    await isCaptchaReady()
    return await grecaptcha.enterprise.execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string, {
      action,
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    reportErrorToBugsnag(err?.message ?? 'grecaptcha failed to retrieve token')
  }
}

export const Captcha = (): JSX.Element => {
  return (
    <Script src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`} />
  )
}
